.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #ffffff;
}

.Container {
  background-color: #282c34;
}

.footer {
  height: -100vh;
  justify-content: center;
  align-items: center;
}

.App-link {
  color: #61dafb;
}

.Card {
  opacity: 0.5;
  filter: alpha(opacity=50);
  /* For IE8 and earlier */
}

.Card:hover {
  opacity: 1.0;
  filter: alpha(opacity=100);
  /* For IE8 and earlier */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.jumbotron {
  margin-bottom: none;
  align-items: center;
  justify-content: center;
}

.embed-responsive-item {
  height: 100vh;
  width: 100vw;
}